import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 960.000000 827.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,827.000000) scale(0.100000,-0.100000)">
          <path d="M1050 8150 c0 -73 4 -120 10 -120 6 0 10 -7 10 -15 0 -8 5 -15 10
-15 6 0 10 7 10 15 0 8 -4 15 -10 15 -6 0 -10 47 -10 120 0 73 -4 120 -10 120
-6 0 -10 -47 -10 -120z"/>
          <path d="M1105 7980 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
          <path d="M1003 7968 c-12 -19 -16 -1345 -4 -1393 6 -24 11 -360 12 -822 3
-765 3 -783 -16 -797 -19 -13 -19 -15 -3 -33 10 -10 22 -37 27 -59 8 -37 9
-35 10 29 1 43 5 66 11 62 7 -4 10 240 10 723 0 620 2 734 15 758 12 23 15 25
21 11 3 -9 10 -17 16 -17 8 0 54 -40 114 -98 8 -8 18 -12 24 -10 5 2 -22 37
-61 78 -97 105 -109 119 -109 130 0 6 -4 10 -10 10 -5 0 -10 9 -10 19 0 11 -4
23 -10 26 -7 4 -10 248 -10 701 0 427 -4 694 -9 694 -6 0 -13 -6 -18 -12z"/>
          <path d="M4841 7873 c-1 -6 -10 -15 -22 -18 -15 -5 -18 -10 -10 -18 6 -6 11
-19 11 -29 0 -10 5 -18 10 -18 6 0 10 -12 11 -27 1 -26 2 -25 9 6 5 19 5 53 0
75 -4 23 -8 36 -9 29z"/>
          <path d="M6627 7803 c-19 -21 -62 -64 -94 -95 -32 -32 -116 -116 -186 -188
-71 -71 -132 -130 -136 -130 -8 0 -34 -41 -28 -46 3 -4 405 392 476 470 38 41
8 30 -32 -11z"/>
          <path d="M6033 7206 c-61 -61 -122 -120 -135 -132 -12 -12 -51 -51 -85 -88
-34 -36 -66 -66 -72 -66 -6 0 -11 -4 -11 -8 0 -4 -97 -106 -216 -225 -119
-120 -214 -220 -211 -223 4 -5 62 52 335 328 34 34 96 96 137 137 41 41 75 78
75 83 0 4 5 8 10 8 12 0 120 111 120 122 0 5 4 8 9 8 13 0 162 152 159 161 -2
4 -53 -43 -115 -105z"/>
          <path d="M6770 6955 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
          <path d="M8648 5993 c-2 -417 0 -769 3 -783 4 -14 8 327 8 758 1 430 -1 782
-3 782 -2 0 -6 -341 -8 -757z"/>
          <path d="M5275 6448 c-4 -10 -13 -18 -21 -18 -8 0 -14 -5 -14 -11 0 -6 -21
-31 -46 -56 -25 -24 -44 -46 -42 -49 9 -8 139 128 135 139 -3 7 -8 5 -12 -5z"/>
          <path d="M5076 6250 c-28 -27 -47 -52 -44 -55 5 -6 108 90 108 100 0 13 -19
-1 -64 -45z"/>
          <path d="M4987 6164 c-26 -35 -13 -43 17 -11 13 14 21 28 18 31 -9 10 -16 6
-35 -20z"/>
          <path d="M2947 6016 c-10 -68 -9 -224 2 -230 12 -8 21 128 13 209 l-7 70 -8
-49z"/>
          <path d="M2948 5604 c-11 -11 -10 -174 1 -174 13 0 21 27 20 68 0 55 -13 115
-21 106z"/>
          <path d="M3775 5586 c17 -14 35 -25 40 -25 11 0 -20 25 -50 41 -11 5 -6 -2 10
-16z"/>
          <path d="M3895 5520 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
          <path d="M3638 5493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
          <path d="M3946 5485 c10 -8 23 -15 28 -15 6 0 1 7 -10 15 -10 8 -23 15 -28 15
-6 0 -1 -7 10 -15z"/>
          <path d="M4000 5451 c0 -10 52 -51 65 -51 11 0 -5 19 -35 40 -17 12 -30 17
-30 11z"/>
          <path d="M3770 5430 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
          <path d="M4086 5386 c3 -8 12 -17 20 -20 8 -3 14 -10 14 -16 0 -14 -27 -13
-33 3 -4 7 -6 6 -6 -4 -1 -22 25 -32 48 -17 17 10 16 13 -12 39 -34 33 -39 35
-31 15z"/>
          <path d="M3881 5367 c2 -1 13 -9 24 -17 19 -14 19 -14 6 3 -7 9 -18 17 -24 17
-6 0 -8 -1 -6 -3z"/>
          <path d="M3940 5335 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z" />
          <path d="M4195 5278 c22 -24 43 -44 47 -46 4 -2 5 -9 2 -16 -3 -8 4 -16 15
-19 12 -3 21 -10 21 -15 0 -6 -9 -8 -20 -5 -12 3 -20 0 -20 -6 0 -7 4 -10 9
-6 5 3 13 0 16 -6 4 -5 14 -8 22 -5 9 4 22 1 30 -5 7 -7 16 -10 18 -7 7 7
-155 178 -169 178 -5 0 8 -19 29 -42z"/>
          <path d="M4045 5300 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
          <path d="M3691 5274 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
          <path d="M4020 5259 c7 -11 14 -18 17 -15 8 8 -5 36 -17 36 -7 0 -7 -6 0 -21z" />
          <path d="M4160 5275 c0 -10 40 -45 51 -45 7 0 0 11 -15 25 -27 25 -36 30 -36
20z"/>
          <path d="M2939 5198 c-1 -29 -2 -69 -3 -88 -1 -25 3 -35 14 -35 11 0 15 16 17
65 2 36 1 75 -2 88 -10 40 -25 23 -26 -30z"/>
          <path d="M4062 5216 c7 -8 15 -12 17 -11 5 6 -10 25 -20 25 -5 0 -4 -6 3 -14z" />
          <path d="M4130 5222 c0 -5 7 -15 15 -22 8 -7 15 -8 15 -2 0 5 -7 15 -15 22 -8
7 -15 8 -15 2z"/>
          <path d="M4090 5202 c0 -5 7 -15 15 -22 8 -7 15 -8 15 -2 0 5 -7 15 -15 22 -8
7 -15 8 -15 2z"/>
          <path d="M3780 5171 c0 -9 30 -24 36 -18 2 1 -6 8 -16 15 -11 7 -20 8 -20 3z" />
          <path d="M4137 5138 c20 -23 22 -22 20 1 -1 12 -8 19 -19 18 -16 -1 -16 -3 -1
-19z"/>
          <path d="M4358 5154 c20 -9 14 -24 -10 -25 -19 -1 -17 -3 7 -9 30 -8 30 -8 -8
-9 -30 -1 -39 3 -46 22 -9 21 -9 21 -16 2 -11 -33 -21 -49 -34 -53 -9 -3 -11
2 -6 19 4 17 3 20 -3 10 -7 -9 -20 -12 -41 -8 -31 6 -31 6 -17 -14 10 -16 12
-28 5 -46 -17 -45 -11 -132 13 -174 l22 -39 13 32 c10 23 10 33 2 39 -6 3 -13
27 -16 52 -5 40 -1 51 27 91 36 50 70 63 140 53 60 -8 92 -44 98 -111 6 -71
-10 -109 -56 -137 -44 -25 -107 -21 -153 12 -23 16 -29 17 -29 5 0 -16 57 -49
71 -40 5 3 6 -2 3 -10 -8 -20 -6 -20 -48 5 -26 15 -36 17 -36 7 1 -17 68 -48
108 -48 22 0 36 -7 50 -27 21 -31 62 -141 62 -167 0 -13 12 -16 74 -16 110 0
108 -2 74 88 -39 104 -71 171 -78 167 -11 -7 -4 40 9 64 22 39 18 120 -7 168
-30 58 -76 91 -137 97 -28 3 -44 3 -37 0z m109 -50 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-237 -29 c11 -13 10 -14 -4 -9 -9 3 -16 10 -16
15 0 13 6 11 20 -6z m-15 -52 c-10 -36 -6 -105 7 -120 5 -7 8 -20 6 -30 -3
-14 -8 -11 -21 14 -19 36 -22 108 -7 149 13 34 25 24 15 -13z m282 5 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m20 -60 c-3 -7 -5 -2 -5 12 0
14 2 19 5 13 2 -7 2 -19 0 -25z m8 -66 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10
-3 15 -9 12 -12z m-28 -94 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-137 -2 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m40 -6
c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m50
-59 c0 -5 3 -18 6 -28 6 -16 5 -16 -9 2 -19 23 -21 35 -7 35 6 0 10 -4 10 -9z
m53 -158 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"/>
          <path d="M4205 5131 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />
          <path d="M3880 5090 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
          <path d="M3710 5050 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
          <path d="M3930 5042 c0 -5 16 -20 35 -33 19 -13 36 -31 39 -39 3 -13 4 -13 5
0 0 8 -17 30 -39 48 -22 18 -40 29 -40 24z"/>
          <path d="M4020 4949 c0 -17 27 -45 35 -37 3 3 -3 16 -14 29 -16 19 -20 20 -21
8z"/>
          <path d="M4010 4911 c0 -8 -8 -11 -18 -9 -11 3 -19 0 -19 -8 0 -8 6 -14 12
-14 16 0 40 28 31 37 -3 3 -6 1 -6 -6z"/>
          <path d="M3880 4895 c7 -9 15 -13 17 -11 7 7 -7 26 -19 26 -6 0 -6 -6 2 -15z" />
          <path d="M3917 4899 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z" />
          <path d="M2937 4863 c-4 -3 -6 -34 -5 -67 l1 -61 7 65 c7 70 7 73 -3 63z" />
          <path d="M3910 4860 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
          <path d="M3682 4203 c-10 -21 -32 -60 -50 -87 -18 -28 -32 -58 -32 -68 0 -10
-4 -18 -10 -18 -5 0 -10 -5 -10 -10 0 -9 -118 -262 -159 -340 -10 -19 -24 -52
-30 -72 -7 -20 -21 -54 -31 -75 l-18 -38 55 -3 c32 -2 61 2 69 9 8 7 33 50 54
96 l39 83 140 0 c158 0 142 10 161 -100 7 -36 16 -71 22 -77 12 -15 128 -18
128 -4 0 5 -15 72 -34 148 -18 76 -50 210 -70 298 -21 88 -44 182 -52 209 -7
26 -14 57 -14 67 0 17 -8 19 -70 19 l-71 0 -17 -37z m106 -255 c18 -90 33
-166 32 -170 0 -7 -220 -12 -220 -5 0 1 26 61 59 134 32 72 64 147 71 167 6
20 15 36 18 36 4 0 22 -73 40 -162z"/>
          <path d="M4185 4228 c-4 -12 -35 -261 -73 -585 l-18 -153 63 0 c72 0 66 -9 83
125 24 182 20 170 64 168 22 -1 43 -6 47 -10 5 -5 32 -62 61 -128 76 -171 62
-156 146 -153 l71 3 -59 123 c-33 68 -60 127 -60 130 0 4 -9 18 -20 32 -22 28
-25 40 -10 40 23 0 94 57 122 98 26 38 32 57 35 116 4 65 2 75 -23 113 -47 71
-116 92 -302 93 -89 0 -124 -3 -127 -12z m282 -108 c67 -42 53 -173 -22 -220
-25 -15 -50 -20 -104 -20 l-72 0 5 33 c3 17 10 72 16 121 6 50 14 94 17 99 8
13 134 3 160 -13z"/>
          <path d="M4815 4228 c-3 -7 -18 -130 -35 -273 -29 -250 -45 -379 -55 -437 -4
-28 -4 -28 55 -28 l60 0 0 -62 c0 -35 4 -58 8 -52 4 6 17 16 30 23 l23 12 -22
39 -22 38 22 198 c12 110 31 269 42 354 27 218 31 200 -41 200 -39 0 -62 -4
-65 -12z"/>
          <path d="M5115 4230 c-5 -8 -95 -694 -95 -727 0 -9 20 -13 65 -13 46 0 65 4
65 13 0 15 28 239 35 285 6 30 9 32 45 32 44 0 28 21 172 -230 l54 -95 72 -3
c40 -2 72 -1 72 2 0 3 -8 20 -19 39 -25 45 -92 169 -131 244 -18 34 -41 71
-51 82 -11 11 -19 22 -19 25 0 3 15 22 34 43 19 21 86 99 149 173 l115 135
-47 3 c-85 6 -103 -3 -162 -76 -29 -37 -63 -78 -75 -92 -12 -14 -45 -53 -73
-87 -46 -56 -56 -63 -87 -63 -34 0 -35 1 -29 33 6 37 35 267 35 279 0 12 -118
10 -125 -2z"/>
          <path d="M5737 4233 c-3 -5 -7 -28 -8 -53 l-4 -45 158 -2 c88 -1 161 -4 164
-7 3 -2 -21 -40 -53 -83 -115 -156 -162 -220 -229 -313 -37 -52 -71 -97 -74
-100 -18 -14 -71 -106 -71 -122 0 -17 15 -18 260 -18 200 0 262 3 264 13 3 6
7 29 10 50 l5 37 -168 0 c-92 0 -170 3 -173 6 -5 4 152 228 302 429 100 135
120 166 120 190 l0 25 -249 0 c-138 0 -252 -3 -254 -7z"/>
          <path d="M6434 4208 c-122 -82 -158 -233 -78 -326 10 -12 62 -40 116 -63 59
-25 103 -50 109 -62 18 -33 5 -114 -21 -137 -54 -47 -139 -45 -202 4 -19 14
-42 26 -51 26 -17 0 -67 -59 -67 -78 0 -18 82 -68 132 -80 26 -7 64 -12 86
-12 146 0 273 138 260 282 -7 72 -30 97 -144 153 -56 28 -109 56 -118 64 -26
22 -21 90 10 126 23 27 31 30 87 30 49 0 69 -5 94 -23 39 -28 61 -23 92 18 26
36 20 45 -55 85 -38 20 -61 25 -124 25 -71 0 -82 -3 -126 -32z"/>
          <path d="M6895 4228 c-2 -7 -16 -118 -31 -247 -28 -252 -27 -316 6 -382 76
-148 333 -158 455 -17 72 84 85 135 125 476 11 95 20 175 20 178 0 3 -28 4
-62 2 l-63 -3 -7 -40 c-4 -22 -19 -132 -33 -245 -14 -113 -31 -220 -36 -238
-18 -55 -79 -109 -132 -117 -93 -14 -156 34 -164 124 -3 25 7 141 21 257 36
288 39 264 -34 264 -40 0 -62 -4 -65 -12z"/>
          <path d="M7626 4231 c-6 -9 -96 -693 -96 -729 0 -11 36 -13 183 -10 167 3 186
5 225 26 91 48 139 130 130 222 -6 56 -37 99 -90 121 l-29 12 37 21 c20 12 51
38 68 59 25 31 31 49 34 102 5 81 -18 123 -86 157 -42 20 -64 23 -208 26 -109
3 -164 0 -168 -7z m283 -102 c39 -14 51 -35 51 -89 0 -61 -25 -101 -75 -120
-25 -10 -175 -15 -175 -6 0 8 20 161 26 194 l5 32 68 0 c37 0 82 -5 100 -11z
m-10 -324 c34 -17 41 -33 41 -86 0 -96 -47 -129 -181 -129 -89 0 -91 0 -86 23
3 12 9 58 13 102 4 44 9 83 10 88 1 4 2 9 3 12 3 12 174 4 200 -10z"/>
          <path d="M8720 3582 c0 -5 -12 -15 -26 -23 -14 -7 -31 -25 -37 -39 -14 -36
-17 -51 -6 -44 5 3 9 -12 9 -34 0 -49 20 -152 30 -152 12 0 20 69 13 116 -4
30 -2 44 6 44 7 0 11 21 11 58 0 32 5 63 12 70 9 9 9 12 0 12 -7 0 -12 -4 -12
-8z"/>
          <path d="M4842 3288 c-3 -62 -18 -102 -37 -94 -23 9 -26 -9 -5 -32 11 -12 20
-30 20 -42 0 -11 5 -20 10 -20 6 0 11 -55 12 -142 l2 -143 5 150 c4 83 3 200
0 260 -3 61 -6 89 -7 63z"/>
          <path d="M8700 3261 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
          <path d="M3888 2873 c17 -2 32 -8 32 -13 0 -5 7 -7 15 -4 27 11 4 24 -38 22
-37 -1 -38 -2 -9 -5z"/>
          <path d="M3794 2846 c-10 -8 -17 -17 -14 -19 3 -3 14 1 24 9 11 7 17 16 15 19
-3 3 -14 -2 -25 -9z"/>
          <path d="M3960 2836 c0 -9 30 -14 35 -6 4 6 -3 10 -14 10 -12 0 -21 -2 -21 -4z" />
          <path d="M3810 2809 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z" />
          <path d="M3965 2750 c0 -32 3 -53 7 -47 4 7 8 28 8 47 0 19 -4 40 -8 47 -4 6
-7 -15 -7 -47z"/>
          <path d="M3990 2800 c0 -5 5 -10 10 -10 6 0 10 -9 10 -20 0 -11 4 -20 9 -20 6
0 6 12 0 30 -10 28 -29 41 -29 20z"/>
          <path d="M4845 2390 c0 -201 1 -283 2 -183 2 101 2 265 0 366 -1 100 -2 18 -2
-183z"/>
          <path d="M3800 2720 c1 -8 7 -24 15 -34 19 -25 19 -16 0 20 -8 16 -15 22 -15
14z"/>
          <path d="M3670 2709 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
          <path d="M3625 2690 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
          <path d="M3967 2656 c-20 -14 -53 -27 -74 -29 l-38 -4 39 -1 c29 -2 51 5 78
24 34 24 44 34 34 34 -2 0 -19 -11 -39 -24z"/>
          <path d="M3770 2610 c-8 -5 -12 -11 -10 -14 3 -2 14 2 24 10 22 15 10 19 -14
4z"/>
          <path d="M6770 1690 c0 -39 -4 -59 -10 -55 -6 4 -10 3 -9 -2 4 -25 -3 -35 -21
-30 -27 7 -25 -7 8 -56 16 -23 33 -53 37 -67 5 -14 12 -353 15 -755 l6 -730
-2 750 c-1 413 -5 776 -9 807 -5 40 -2 73 9 111 20 68 20 64 -4 77 -19 10 -20
8 -20 -50z"/>
          <path d="M2913 1148 c-6 -7 -13 -21 -16 -31 -6 -15 -2 -18 16 -15 16 2 23 11
25 31 3 28 -9 36 -25 15z"/>
          <path d="M2930 516 c0 -343 3 -516 10 -516 14 0 13 1017 0 1025 -7 4 -10 -181
-10 -509z"/>
          <path d="M4840 165 c0 -41 4 -55 14 -55 22 0 29 36 16 75 -17 51 -30 43 -30
-20z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
